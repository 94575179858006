









import Vue from 'vue'
import Component from 'vue-class-component'

import GroupConfigView from './GroupConfig.vue'

@Component({
  components: { GroupConfigView }
})
export default class ConfigView extends Vue {
}
