

































































import Vue from 'vue'
import Component from 'vue-class-component'

import { groupConfigApi } from '../api/firebaseApi'
import GroupConfigApi, { Group } from '../api/api'

@Component({})
export default class GroupConfigView extends Vue {
  api: GroupConfigApi = groupConfigApi

  groupWithConfig: Group = {
    id: '',
    name: '',
    emails: [],
    config: {
      printHeader: ''
    }
  }
  
  isLoading = false
  groupConfigIsDirty = false

  mounted (): void {
    void this.loadGroupData()
  }

  async loadGroupData (): Promise<void> {
    this.isLoading = true
    this.groupWithConfig = await this.api.getGroupWithConfig()
    this.isLoading = false
    this.groupConfigIsDirty = false
  }

  onChange (): void {
    this.groupConfigIsDirty = true
  }

  async onUpdate (): Promise<void> { 
    if (!this.groupConfigIsDirty) {
      return
    }
    await this.api.updateGroupConfig(this.groupWithConfig.config)
    this.groupConfigIsDirty = false
  }

  onCancel (): void {
    void this.loadGroupData()
  }
}
